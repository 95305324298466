<template>
  <Loading :isShow="isLoading">
    <div class="top-logo-container">
      <img :src="homeImage" alt="Client-Slalom White Logo" />
    </div>
    <div v-if="homeContent" class="heading">
        <p class="page-title">{{ homeContent.homeTitle }}</p>
        <div id="login-subtitle" v-html="homeContent.homeSubtitle" class="page-subtitle"></div>
    </div>
    <div class = "login-wrapper">
        <div class="login">
            <div class="login-content">
                <template v-if="loginSection"><p>{{loginSection.title}}</p></template>
                <div class="input-wrapper">
                    <input type="text" name="username" v-model="input.username" placeholder="Username" />
                </div>
                <div class="input-wrapper">
                    <input type="password" name="password" v-model="input.password" placeholder="Password"/>
                </div>
                <template v-if="loginSection" ><p v-if="showErrorMessage" class="error-message">{{loginSection.warningText}}</p></template>
                <div class="input-wrapper">
                    <button type="button" v-on:click="login()">Sign In</button>
                </div>
            </div>
        </div>
    </div>
  </Loading>
</template>

<script>
import api from '@/services/api.js';
import Loading from '@/components/Loading.vue';
import Navigation from '@/components/Navigation.vue';
import HomeNavigation from '@/components/HomeNavigation.vue';
import SESSION_AUTH_KEY from '@/config.js';

export default {
	data: () => ({
		isLoading: true,
		isError: false,
		errorMessage: '',
		showErrorMessage: false,
		input: {
			username: '',
			password: ''
		},
	}),
  computed: {
    homeContent () {
      const homeContent = {
        homeTitle: "",
        homeSubtitle: "",
      }
      const data = this.$store.state.rootItem;
      if(data){
        homeContent.homeTitle = data.title;
        homeContent.homeSubtitle = data.subtitle.text;
      }
      return homeContent;
    },
    homeImage () {
      const data = this.$store.state.rootItem;
      if(data){
        return data.metadata.headerLogo;
      }
      return null;
    },
    loginPageId() {
      const loginPage = this.$store.state.loginPage;
      if(loginPage){
        return this.$store.state.loginPage.id;
      }
      return null;
    },
  },
  watch: {
    loginPageId(newValue, oldValue) {
      if (newValue) {
        this.getData()
      }
    }
  },
	methods: {
		async getData() {
			this.isError = false;
			this.isLoading = true;
			try {
        const { data } = await api.getLoginPage(this.loginPageId);
        const rootItem = this.$store.state.rootItem;
        this.homeContent = {
          title: rootItem.title,
          subtitle: rootItem.subtitle.text,
        }
        this.loginSection = data.loginSection;
        this.content = data.content;
				this.images = data.images;
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		},

    async login() {
			if(this.input.username != '' && this.input.password != '') {
				try {
					this.isLoading = true;
					const { data } = await api.authUser(this.input.username, this.input.password);
					if (data.authorized) {
						sessionStorage.setItem(SESSION_AUTH_KEY, true);
						this.showErrorMessage = false;
						this.$router.push('/')
					} else {
						this.showErrorMessage = true;
					}
				} catch (err) {
					this.isError = true;
					this.errorMessage = err.message;
					this.showErrorMessage = true;
				} finally {
					this.isLoading = false;
				}
			} else {
				this.showErrorMessage = true;
			}
		},
	},

	async beforeRouteUpdate(to, from, next) {
		next();
	},

  async created() {
    if(this.loginPageId){
      await this.getData();
    }
  },

	components: {
		Loading,
		Navigation,
		HomeNavigation,
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";
.top-logo-container {
  background-color: $secondary-color-4;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  padding: 0 1em;
  text-align: center;
}

.login {
    @include border-radius(2);
    max-width: 1000px;
    border-bottom-right-radius: 3em;
    border: 1px solid #CCCCCC;
    background-color: $secondary-color-9;
    margin: auto;
    margin-top: 3%;
    box-shadow: 0px 4px 10px 0px #00000026;
    @include responsive(phone) {
        @include margin(1);
    }
    @include responsive(tablet) {
        max-width: 600px;
        @include margin(1);
    }
}

.login button {
    background-color: $primary-color-2;
    color: $secondary-color-9;
    @include padding(2 6 2 6);
    @include border-radius(2);
    @include animate;
    border: none;
    font-size: 16px;
    font-family: 'Slalom Sans';
    &:hover {
        opacity: 0.7;
    }
    &:active {
        opacity: 0.5;
    }
    @include responsive(phone) {
        flex-grow: 1;
    }
    @include responsive(tablet) {
       flex-grow: 1;
    }
}

.login input {
    @include padding(2 2 2 2);
    flex-grow: 1;
    font-family: 'Slalom Sans';
    color: $secondary-color-7;
}

.input-wrapper {
    display: flex;
}

.login div {
    @include margin(3 0 3 0);
}

.login p {
    color: $secondary-color-7;
}

.page-subtitle {
  color: $secondary-color-8;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 0;
  text-transform: uppercase;
}

.page-title {
  color: $secondary-color-7;
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 0;
}

.login-content {
    @include padding(6);
    @include responsive(phone) {
        @include padding(2);
    }
    @include responsive(tablet) {
        @include padding(2);
    }
}

.login-wrapper {
    display: flex;
    justify-content: center;
}
</style>

<style lang="scss">

#login-subtitle > p {
  color: #a1a4ac;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 0;
  text-transform: uppercase;
}

</style>
